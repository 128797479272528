/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { graphql } from "gatsby";

import Markdown from "react-markdown";
import { Box, Heading, Flex, Button } from "rebass";
import MainContainer from "../../ui-components/container/container";
import Map from "./map";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    unlimitedParagraphs: [
      {
        text: {
          text: string;
        };
      }
    ];
  };
  css?: any;
}

export const query = graphql`
  fragment LocationsServiced on ContentfulLocationsServiced {
    title
    unlimitedParagraphs {
      text {
        text
      }
    }
    internal {
      type
    }
  }
`;

const LocationsServiced: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  data,
  ...rest
}) => {
  const { title, unlimitedParagraphs } = data;

  return (
    <Map>
      <MainContainer color="white" sx={{ background: "rgba(57, 61, 63, 0.7);"}} m={[2, 2, "50px auto"]}>
        <Box p="25px" width="100%">
          <Heading variant="subheading">{title}</Heading>
          <Flex justifyContent="space-between">
            {unlimitedParagraphs.map(({ text: { text } }) => (
              <Box flex="0 1 32%" maxWidth="33%" key={text}>
                <Markdown source={text} />
              </Box>
            ))}
          </Flex>
        </Box>
      </MainContainer>
    </Map>
  );
};

export default LocationsServiced;
