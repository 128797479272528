/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC, ReactNode } from "react";
import { Flex, Text, Box, Link } from "rebass";
import MainContainer from "../container/container";
// @ts-ignore
import IG from "../../assets/svgs/insta.svg";
// @ts-ignore
import FB from "../../assets/svgs/facebook.svg";
// @ts-ignore
import Email from "../../assets/svgs/email.svg";

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;

  css?: any;
}

const Nav: FC<Props & JSX.IntrinsicElements["nav"]> = ({
  className = "",
  id = "",
  children,
  ...rest
}) => {
  return (
    <Box backgroundColor="#0B3C49" py={[2, 4]} px={[0, 4]}>
      <Flex as="footer" alignItems="center">
        <MainContainer py={4} sx={{}} justifyContent="space-between">
          <Flex flexWrap="wrap" height="fit-content" flex={["0 1 50%", "0 1 50%", "0 1 50%"]} maxWidth={["50%", "50%", "50%"]}>
            <Link variant="footerHeader" href="/" flex="0 1 100%" maxWidth="100%">
              Rodriguez Carpet and More
            </Link>
            <Box flex="0 1 100%" maxWidth="100%" mt={3}>
              <Link color="white" href="https://www.facebook.com/rodriguezcarpetandmore/"><FB class="social-icons" /></Link>
              <Link color="white" href="https://www.instagram.com/rodriguezcarpetandmore/"><IG class="social-icons" /></Link>
              <Link color="white" href="mailto:hello@rodriguezcarpetandmore.com"><Email class="social-icons" /></Link>
            </Box>
            <Box flex="0 1 100%" maxWidth="100%" my={3} color="white">
              <Link href="tel:+13462890303" color="white">(346) 289-0303</Link>
            </Box>
          </Flex>
          <Flex flex={[ "0 1 100%", "0 1 100%", "0 1 75%"]} maxWidth={["100%", "100%", "50%"]} placeContent="flex-end" flexWrap="wrap">
            <Flex flex={["0 1 50%", "0 1 50%", "0 1 48%"]} maxWidth={["50%", "50%", "48%"]} flexWrap="wrap"> 
              <Link variant="footerHeader" href="#">
                Language
              </Link>
              <Link variant="footer" href="/">
                English
              </Link>
              <Link variant="footer" href="/es">
                Spanish
              </Link>
            </Flex>
            <Flex flex={["0 1 50%", "0 1 50%", "0 1 48%"]} maxWidth={["50%", "50%", "48%"]} flexWrap="wrap">
              <Link variant="footerHeader" href="#">
                Legal
              </Link>
              <Link variant="footer" href="/privacy">
                Privacy Policy
              </Link>
              <Link variant="footer" href="/terms-of-service">
                Terms of Service
              </Link>
              <Link variant="footer" href="/cookie-policy">
                Cookie Policy
              </Link>
            </Flex>
          </Flex>
        </MainContainer>
      </Flex>
      <Flex color="white" alignItems="center">
        <MainContainer py={2} textAlign="center">
          <Text fontSize="11px" py={2} fontWeight="bold">
            <span role="img" aria-label="Copyright">
              &copy;
            </span>
            {` ${new Date().getFullYear()} Rodriguez Carpet and More`}
          </Text>
        </MainContainer>
      </Flex>
    </Box>
  );
};

export default Nav;
