/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, useState, createRef } from 'react';
import { graphql } from 'gatsby';
import { Flex, Box, Button, Text } from 'rebass';
import { navigate } from 'gatsby';
import Recaptcha from 'react-google-recaptcha';
import { useForm, Controller } from 'react-hook-form';

import {
  Label,
  Input as RebassInput,
  Checkbox as RebassCheckbox,
  Textarea as RebassTextarea
} from '@rebass/forms';

import Heading from '../../ui-components/heading/heading';
import { VALIDATIONSCHEMEA, INITIALVALUES } from './constants';

export interface FormContentProps {
  title: string;
  buttonText: string;
}
interface Props {
  className?: string;
  id?: string;
  data?: {
    title: string;
    buttonText: string;
  };
  modalClick?: any;
  css?: any;
}

export const query = graphql`
  fragment Form on ContentfulForm {
    title
    buttonText
    internal {
      type
    }
  }
`;

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

// const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY;
const RECAPTCHA_KEY = '6LfI8_gUAAAAAF4xPO8hshZ5H1emoyWxAUquS5xW';
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables. 
  Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
  Note this demo is specifically for Recaptcha v2
  `);
}

const FormWrapper: FC<Props & JSX.IntrinsicElements['div']> = ({
  data,
  modalClick
}) => {
  const recaptchaRef = createRef();

  const { register, handleSubmit, errors, control, reset } = useForm({
    validationSchema: VALIDATIONSCHEMEA,
    defaultValues: INITIALVALUES
  });

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [comment, setComment] = useState('');
  const [zip, setZip] = useState('');
  const [call, setCall] = useState(true);
  const [text, setText] = useState(true);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values, e) => {
    // @ts-ignore
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        'g-recaptcha-response': recaptchaValue,
        ...values
      })
    })
      .then(() => {
        setIsSubmitting(false);
        console.log(values);
        navigate(
          document.getElementById('js-netlify-form').getAttribute('action')
        );
      })
      .catch(error => {
        alert('Error: Please Try Again!');
        setIsSubmitting(false);
      });
  };

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="baseline">
        <Heading as="h4" variant="formTitle">
          {data && data.title || 'Request a quote'}
        </Heading>
        {modalClick && (
          <Button
            sx={{
              right: 0,
              border: 0,
              bg: 'transparent',
              color: 'primary',
              padding: 0
            }}
            onClick={modalClick}
          >
            x
          </Button>
        )}
      </Flex>

      <Box
        as="form"
        className="form"
        name="contact"
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
        action="/thanks"
        data-netlify="true"
        data-netlify-recaptcha="true"
        sx={{
          maxWidth: '350px'
        }}
        id="js-netlify-form"
      >
        <noscript>
          <p>This form won’t work with Javascript disabled</p>
        </noscript>
        <Flex flexWrap="wrap">
          <Flex
            flex={['0 1 100%']}
            maxWidth={['100%']}
            justifyContent="space-between"
          >
            <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
              <Label htmlFor={name}>Name</Label>
              <Controller
                as={RebassInput}
                id="name"
                name="name"
                aria-invalid={errors.name ? 'true' : 'false'}
                aria-describedby="nameError"
                control={control}
                fontSize={3}
              />
              {errors.name && <Text id="nameError">{errors.name.message}</Text>}
            </Box>
            <Box flex={['0 1 49%']} maxWidth={['49%']} my={1}>
              <Label htmlFor={phone}>Phone</Label>
              <Controller
                as={RebassInput}
                id="phone"
                name="phone"
                aria-invalid={errors.phone ? 'true' : 'false'}
                aria-describedby="phoneError"
                control={control}
                fontSize={3}
              />
              {errors.phone && (
                <Text id="phoneError">{errors.phone.message}</Text>
              )}
            </Box>
          </Flex>
          <Flex
            flex={['0 1 100%']}
            maxWidth={['100%']}
            justifyContent="space-between"
            py={2}
          >
            <Box flex={['0 1 70%']} maxWidth={['70%']}>
              <Label htmlFor={email}>Email</Label>
              <Controller
                as={RebassInput}
                id="email"
                name="email"
                aria-invalid={errors.email ? 'true' : 'false'}
                aria-describedby="emailError"
                control={control}
                fontSize={3}
              />
              {errors.email && (
                <Text id="emailError">{errors.email.message}</Text>
              )}
            </Box>
            <Box flex={['0 1 28%']} maxWidth={['28%']} mt="auto">
              <Label>
                <RebassCheckbox
                  name="call"
                  id="call"
                  value={call}
                  onChange={() => setCall(true)}
                  disabled={isSubmitting}
                  ref={register}
                  fontSize={[1]}
                />
                Call
              </Label>
              <Label>
                <RebassCheckbox
                  name="text"
                  id="text"
                  value={text}
                  onChange={() => setText(true)}
                  disabled={isSubmitting}
                  ref={register}
                  defaultChecked
                  fontSize={[1]}
                />
                Text
              </Label>
            </Box>
          </Flex>
          <Flex
            flex={['0 1 100%']}
            maxWidth={['100%']}
            justifyContent="space-between"
            py={2}
          >
            <Box flex={['0 1 70%']} maxWidth={['70%']}>
              <Label htmlFor={address}>Address</Label>
              <Controller
                as={RebassInput}
                id="address"
                name="address"
                aria-invalid={errors.address ? 'true' : 'false'}
                aria-describedby="addressError"
                control={control}
                fontSize={3}
              />
              {errors.address && (
                <Text id="addressError">{errors.address.message}</Text>
              )}
            </Box>
            <Box flex={['0 1 28%']} maxWidth={['30%']}>
              <Label htmlFor={zip}>Zipcode</Label>
              <Controller
                as={RebassInput}
                id="zip"
                name="zip"
                aria-invalid={errors.address ? 'true' : 'false'}
                aria-describedby="zipError"
                control={control}
                fontSize={3}
              />
              {errors.zip && <Text id="zipError">{errors.zip.message}</Text>}
            </Box>
          </Flex>
        </Flex>
        <Flex flexWrap="wrap" my={1}>
          <Box width={1} my={1}>
            <Controller
              as={RebassTextarea}
              id="comment"
              name="comment"
              placeholder="Let us know about speciality pests or other concerns you might have."
              control={control}
            />
          </Box>
        </Flex>
        <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
        <Flex justifyContent="space-between">
          <Button
            name="submit"
            type="submit"
            disabled={isSubmitting}
            className="button"
            variant="primary"
            my={2}
          >
            {data && data.buttonText || 'Submit'}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};

export default FormWrapper;
