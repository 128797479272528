/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, ReactNode } from 'react';
import { Flex, Text, Box, Link } from 'rebass';
import MainContainer from '../container/container';
import { graphql, useStaticQuery } from 'gatsby';
import FormModal from '../../components/form/modal';

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;
  css?: any;
}

const Nav: FC<Props & JSX.IntrinsicElements['nav']> = ({
  className = '',
  id = '',
  children,
  ...rest
}) => {
  const {
    contentfulMenu: { linkList }
  } = useStaticQuery(
    graphql`
      query {
        contentfulMenu {
          linkList {
            visibleText
            slug
          }
        }
      }
    `
  );

  return (
    <Box
      sx={{
        ' @media screen and (max-width: 63.95em)': {
          display: 'none'
        }
      }}
    >
      {/* <Flex as="div" color="white" bg="black" alignItems="center" py={[2]}>
        <MainContainer py={2} textAlign="center">
          <Text fontSize="11px" px={2} fontWeight="bold">
            <Link variant="nav" href="tel:+15555555555" color="white">
              (555) 555-5555
            </Link>
          </Text>
        </MainContainer>
      </Flex> */}
      <Flex as="nav" color="black" bg="white" alignItems="center" py={[3]}>
        <MainContainer width="100%" justifyContent="space-between">
          <Link
            my="auto"
            color="primary"
            href="/"
            display="flex"
            alignItems="center"
          >
            {/* <Mark style={{ height: '50px' }} /> */}
            <Text fontSize={3} mr={2} ml="0">
              Rodriguez Carpet and More
            </Text>
          </Link>
          <Flex my="auto" height="23px" alignItems="center">
            {linkList.map(({ slug, visibleText }) => (
              <Text px={2} variant="caption" key={slug}>
                <Link variant="nav" href={slug}>
                  {visibleText}
                </Link>
              </Text>
            ))}
            <FormModal />
          </Flex>
        </MainContainer>
      </Flex>
    </Box>
  );
};

export default Nav;
