/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import { Box, Heading, Flex, Button } from "rebass";
import MainContainer from "../../ui-components/container/container";

interface Props {
  className?: string;
  id?: string;
  css?: any;
  children: any;
}

// travis + williamson county
const Map: FC<Props & JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  children,
  ...rest
}) => {
  const { staticMap } = useStaticQuery(
    graphql`
      query {
        staticMap {
          childFile {
            publicURL
          }
        }
      }
    `
  );

  return (
    <Flex
      sx={{
        backgroundImage: `url(${staticMap.childFile.publicURL})`,
        backgroundSize: "cover",
        color: "black",
        backgroundPosition: "center"
      }}
    >
      {children}
    </Flex>
  );
};

export default Map;
