import React from "react";
import { Helmet } from "react-helmet";

import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";
import MobileNavbar from "./mobile-navbar/mobile-navbar";

import "./layout.scss";

// import { useSiteMetadata } from '../hooks/use-site-metadata.ts';

import "../global.scss";


// import favicon from '../assets/images/favicon.png';

const Layout = ({ children }: any) => {
  return (
    <>
      <Helmet>
        {/* {metatags.map(({ key, value }) => {
          let tag;
          if (key === 'title') {
            tag = <title key={key}>{value}</title>;
          } else if (key === 'canonical') {
            tag = <link key={key} rel={key} href={value} />;
            overrideCanonical = true;
          } else if (tagsWithName.indexOf(key) !== -1) {
            tag = <meta key={key} name={key} content={value} />;
          } else {
            tag = <meta key={key} property={key} content={value} />;
          }

          return tag;
        })} */}

        <html lang="en" />
        <link
          href="https://fonts.googleapis.com/css?family=Roboto|Lato&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navbar />
      <MobileNavbar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
