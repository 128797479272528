/** @jsx jsx */
import { FC } from "react";
import { jsx } from "@emotion/core";

import { Link as GatsbyLink } from "gatsby";

import { Link as RebassLink } from "rebass";

interface Props {
  disabled?: boolean;
}

const LinkFoundation: FC<JSX.IntrinsicElements["a"] & Props> = ({
  href,
  disabled,
  children,
  ...rest
}) => {
  const internalRegex = /^https?:\/\/([a-zA-Z\d-]+\.){0,}rodriguezcarpetandmore\.com$/;
  const internal = href!.match(internalRegex);

  if (internal) {
    // @ts-ignore
    return (
      <GatsbyLink
        // @ts-ignore
        to={disabled ? undefined : href}
        data-emilie="true"
        {...rest}
      >
        {children}
      </GatsbyLink>
    );
  }
  return <RebassLink href={disabled ? undefined : href}>{children}</RebassLink>;
};

export default LinkFoundation;
