import React, { useState } from 'react';
import Transition from 'react-transition-group/Transition';
import { Flex, Text, Box, Link, Button } from "rebass";
import HamburgerIcon from './components/hamburger-icon/hamburger-icon';
import { graphql, useStaticQuery } from "gatsby";

const MobileNavbar = assignedColor => {
  const {
    contentfulMenu: {
      linkList
    }
  } = useStaticQuery(
    graphql`
      query {
        contentfulMenu {
          linkList {
            visibleText
            slug
          }
        }
      }
    `
  );

  const [menuOpen, setMenuOpen] = useState(false);

  const handleHamburgerClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const handleCloseButtonClick = () => {
    setMenuOpen(false);
  };

  const lightHeader = assignedColor.color === 'white' && !menuOpen;

  return (
    <Box color="white">
      <Box
        as="nav"
        variant={menuOpen ? 'mobileMenuOpened' : 'mobileMenuClosed'}
      >
        <Flex>
          <Link my="auto" color="green" href="/" display="flex" alignItems="center">
            <Text fontSize={3} ml={2}>Rodriguez Carpet and More</Text>
          </Link>
          <Box ml="auto">
            <HamburgerIcon
              open={menuOpen}
              onClick={handleHamburgerClick}
              color={lightHeader ? 'white' : 'black'}
            />
          </Box>
        </Flex>
      </Box>

      <Transition in={menuOpen} timeout={150} mountOnEnter unmountOnExit>
        <Box bg='white' 
              height="100%" 
              left="0" 
              overflow="scroll" 
              p="88px 24px 0"  
              width="100%"
              sx={{
                top: "0", 
                transition: 'opacity 0.15s, transform 0.15s', 
                position: "fixed",
                willChange: 'transform, opacity',
                zIndex: 10
                }}
        >
          <Box display={menuOpen ? 'block' : 'none'}>
            {linkList.map(({ slug, visibleText }) => (
              <Text px={2} variant="caption" key={slug} py={2}>
                <Link variant="mobileNav" href={slug} onClick={handleLinkClick}>
                  {visibleText}
                </Link>
              </Text>
            ))}
          </Box>
        </Box>
      </Transition>

      <Transition in={menuOpen} timeout={150} unmountOnExit mountOnEnter>
        <Button
          type="button"
          varient="closeButtonBottom"
          onClick={handleCloseButtonClick}
        >
          &times;
        </Button>
      </Transition>
    </Box>
  );
};

export default MobileNavbar;
