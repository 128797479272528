import * as Yup from 'yup';

const INITIALVALUES = {
    name: "",
    phone: "",
    address: "",
    zip: "",
    call: false,
    text: false,
    email: "",
    comment: ""
  };

const VALIDATIONSCHEMEA = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(20, "Too Long!")
      .required("Name is required"),
    phone: Yup.string().matches(
      /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
      {
        message: "Oops, that's not a phone number."
      }
    ),
    call: Yup.bool(),
    text: Yup.bool(),
    address: Yup.string()
      .min(5, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your street address."),
    zip: Yup.string()
      .min(5, "Too Short!")
      .max(5, "Too Long!")
      .required("Oops, what's your zipcode?"),
    email: Yup.string().email(),
    comment: Yup.string()
  });

export { INITIALVALUES, VALIDATIONSCHEMEA } ;