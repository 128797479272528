/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { FC } from "react";
import { graphql } from "gatsby";
import Markdown from "react-markdown";
import Link from "../../ui-components/link/link";
import Heading from "../../ui-components/heading/heading";
import MainContainer from "../../ui-components/container/container";
import Form from "../form/form";

import { Box, Flex, Image, Text } from "rebass";

interface Props {
  className?: string;
  id?: string;
  data: {
    title: string;
    subtitle: {
      subtitle: string;
    };
    paragraphs: [
      {
        title: string;
        subtitle: {
          subtitle: string;
        };
        link: [{
          htmlElement: boolean;
          styledAs: boolean;
          emphasis: boolean;
          slug: string;
          visibleText: string;
        }];
        image: {
          file: {
            url: string;
          };
        };
      }
    ];
  };
  titleAs?: "h1" | "h2";
  subtitleAs?: "h1" | "h2";
  css?: any;
}

export const query = graphql`
  fragment SideBySideParagraphSection on ContentfulSideBySideParagraphSection {
    title
    paragraphs {
      title
      subtitle {
        subtitle
      }
      link {
        htmlElement
        styledAs
        emphasis
        slug
        visibleText
      }
      image {
        file {
          url
        }
      }
    }
    internal {
      type
    }
  }
`;

const SideBySideParagraphFoundation: FC<Props &
  JSX.IntrinsicElements["div"]> = ({
  className = "",
  id = "",
  titleAs = "h1",
  subtitleAs = "h2",
  data: { title, subtitle, paragraphs },
  ...rest
}) => {
  const html = () => {
    if (paragraphs.length === 5) {
      const groupOf3 = paragraphs.slice(0, 3);
      const groupOf2 = paragraphs.slice(3, 5);

      return (
        <Box>
          <Flex justifyContent="space-between" marginBottom={5} flexWrap={["wrap", "wrap", "no-wrap"]}>
            {groupOf3.map(({ title, subtitle, link, image }) => (
              <Box key={title} width={[1, 3 / 10]} sx={{ textAlign: "center" }} py={[4, 4, 0]}>
                <Image src={image.file.url} height="60px" alt={title} />
                {title && (
                  <Text as="h3" my={3} variant="body">
                    {title}
                  </Text>
                )}

                <Markdown source={subtitle.subtitle} />
                {link && link.map(({slug, visibleText}) =>(
                  <Link href={slug}>{visibleText}</Link>
                ))}
              </Box>
            ))}
          </Flex>
          <Flex justifyContent="space-around" flexWrap={["wrap", "wrap", "no-wrap"]}>
            {groupOf2.map(({ title, subtitle, link, image }) => (
              <Box key={title} width={[1, 3 / 10]} sx={{ textAlign: "center" }} py={[4, 4, 0]}>
                <Image src={image.file.url} height="60px" alt={title} />
                {title && (
                  <Text as="h3" my={3} variant="body">
                    {title}
                  </Text>
                )}

                <Markdown source={subtitle.subtitle} />
                {link && link.map(({slug, visibleText}) =>(
                  <Link href={slug}>{visibleText}</Link>
                ))}
              </Box>
            ))}
          </Flex>
        </Box>
      );
    } else if (paragraphs.length === 4) {
      return (
        <Flex justifyContent="space-between" flexWrap={["wrap", "wrap", "no-wrap"]}>
          {paragraphs.map(({ title, subtitle, link, image }) => (
            <Box key={title} width={[1, "23%"]} sx={{ textAlign: "center" }} py={[4, 4, 0]}>
              <Image src={image.file.url} height="60px" />
              {title && (
                <Text as="h3" my={3} variant="body">
                  {title}
                </Text>
              )}

              <Markdown source={subtitle.subtitle} />
              {link && link.visibleText && (
                <Link href={link.slug}>{link.visibleText}</Link>
              )}
            </Box>
          ))}
        </Flex>
      );
    } else {
      return (
        <Flex justifyContent="space-between" flexWrap={["wrap", "wrap", "no-wrap"]}>
          {paragraphs.map(({ title, subtitle, link, image }) => (
            <Box key={title} width={[1, 3 / 10]} sx={{ textAlign: "center" }} py={[4, 4, 0]}>
              <Image src={image.file.url} height="60px" />
              {title && (
                <Text as="h3" my={3} variant="body">
                  {title}
                </Text>
              )}

              <Markdown source={subtitle.subtitle} />
              {link && link.visibleText && (
                <Link href={link.slug}>{link.visibleText}</Link>
              )}
            </Box>
          ))}
        </Flex>
      );
    }
  };

  return (
    <MainContainer padding marginBottom={4}>
      <Flex>
        <Heading as="h2" variant="subheading">{title}</Heading>
        {subtitle && <p>{subtitle.subtitle}</p>}
      </Flex>
      {html()}
    </MainContainer>
  );
};

export default SideBySideParagraphFoundation;
